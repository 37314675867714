<template>
  <div class="flex flex-col h-full m-4">
    <div class="inline-flex items-center my-4">
      <router-link to="/auth/login">
        <svg
          class="w-6 h-6 fill-current "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 512"
        >
          <path
            d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
          />
        </svg>
      </router-link>
      <h1 class="uppercase">
        Terms & Condition
      </h1>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        Dengan mendaftar dan/atau menggunakan situs CHECKIN maka pengguna
        dianggap telah membaca, mengerti, memahami dan menyetujui semua isi
        dalam Syarat & ketentuan. Syarat & ketentuan ini merupakan bentuk
        kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara
        Pengguna dengan PT.Bumi Inovasi Ganapatih. Jika pengguna tidak
        menyetujui salah satu, sebagian, atau seluruh isi Syarat & ketentuan,
        maka pengguna tidak diperkenankan menggunakan layanan CHECKIN.
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        1. PT. Bumi Inovasi Ganapatih merupakan suatu perseroan terbatas yang
        menjalankan kegiatan usaha dalam bidang Teknologi
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        2. Situs Resmi PT.Bumi Inovasi Ganapatih yaitu pointhub.net
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        3. Syarat & ketentuan adalah perjanjian antara Pengguna dan PT.Bumi
        Inovasi Ganapatih yang berisikan seperangkat peraturan yang mengatur
        hak, kewajiban, tanggung jawab pengguna dan PT.Bumi Inovasi Ganapatih,
        serta tata cara penggunaan sistem CHECKIN
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        4. Dalam menggunakan setiap fitur dan/atau layanan CHECKIN, Pengguna
        dilarang untuk mengunggah atau mempergunakan kata-kata, komentar,
        gambar, atau konten apapun yang mengandung unsur SARA, diskriminasi,
        merendahkan atau menyudutkan orang lain, vulgar, bersifat ancaman,
        beriklan atau melakukan promosi ke situs selain Situs CHECKIN, atau
        hal-hal lain yang dapat dianggap tidak sesuai dengan nilai dan norma
        sosial maupun berdasarkan kebijakan yang ditentukan sendiri oleh
        CHECKIN. PT. Bumi Inovasi Ganapatih berhak melakukan tindakan yang
        diperlukan atas pelanggaran.
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        5. Pengguna dengan ini memahami dan menyetujui bahwa penyalahgunaan
        foto/gambar yang di unggah oleh Pengguna adalah tanggung jawab Pengguna
        secara pribadi
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        6. Pengguna menjamin bahwa tidak melanggar hak kekayaan intelektual
        dalam mengunggah konten Pengguna kedalam situs CHECKIN. Setiap Pengguna
        dengan ini bertanggung jawab secara pribadi atas pelanggaran hak
        kekayaan intelektual dalam mengunggah konten di Situs CHECKIN.
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        7. PT.Bumi Inovasi Ganapatih berhak untuk sewaktu-waktu menurunkan
        konten atau materi yang terdapat pada Feed yang dianggap melanggar
        Syarat dan Ketentuan Situs, peraturan hukum yang berlaku, serta etika
        pariwara yang berlaku
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        8. Apabila Pengguna mempergunakan fitur-fitur yang tersedia dalam situs
        CHECKIN maka Pengguna dengan ini menyatakan memahami dan menyetujui
        segala syarat dan ketentuan yang diatur khusus sehubungan dengan
        fitur-fitur tersebut.
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        9. Segala hal yang belum dan/atau tidak diatur dalam syarat dan
        ketentuan khusus dalam fitur tersebut maka akan sepenuhnya merujuk pada
        syarat dan ketentuan CHECKIN secara umum
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        10. Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum
        Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Anda
        setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul
        dari, berhubungan dengan, atau berada dalam cara apapun berhubungan
        dengan situs dan/atau Perjanjian ini akan diselesaikan secara eksklusif
        dalam yurisdiksi pengadilan Republik Indonesia.
      </p>
    </div>
    <div class="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        11. Syarat & ketentuan mungkin diubah dan/atau diperbaharui dari waktu
        ke waktu tanpa pemberitahuan sebelumnya. Kami menyarankan agar anda
        membaca secara seksama dan memeriksa halaman Syarat & ketentuan ini dari
        waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses
        dan menggunakan layanan kami, maka pengguna dianggap menyetujui
        perubahan-perubahan dalam Syarat & Ketentuan.
      </p>
    </div>
    <div class="p-4 mt-2 mb-4 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p>
        12. Biaya Layanan adalah biaya yang dikenakan kepada Pengguna untuk
        tujuan pemeliharaan sistem dan peningkatan layanan dalam bertransaksi
        melalui Situs Point Hub
      </p>
    </div>
  </div>
</template>
